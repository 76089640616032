@font-face {
  font-family: 'Nunito Bold';
  src: url('assets/css/fonts/Nunito/Nunito-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito SemiBold';
  src: url('assets/css/fonts/Nunito/Nunito-SemiBold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Medium';
  src: url('assets/css/fonts/Nunito/Nunito-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Regular';
  src: url('assets/css/fonts/Nunito/Nunito-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('assets/css/fonts/Nunito/Nunito-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito ExtraBold';
  src: url('assets/css/fonts/Nunito/Nunito-ExtraBold.ttf');
  font-weight: normal;
  font-style: normal;
}

h2 {
  line-height: 36px;
  font-size: 24px;
}

h3 {
  line-height: 30px;
  font-size: 21px;
}

.title-font {
  font-family: 'Nunito ExtraBold', 'Times New Roman', serif, Arial;
}

.neutal-theme-color {
  color: #4083ff;
}

#themecolors a {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.w150 {
  width: 150px;
}

.w70 {
  width: 70px;
}

.img-50 {
  width: 50px;
}

.oculto {
  display: none;
}

.fondo-negro {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999999999;
}

.fondo-difuminado {
  -moz-backdrop-filter: blur(11px) brightness(120%);
  -webkit-backdrop-filter: blur(11px) brightness(120%);
  backdrop-filter: blur(11px) brightness(120%);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999999999;
}

.cursor {
  cursor: default;
}

.title-center {
  text-align: center;
}

/*****CLASSROOMS*****/
.classroom_title {
  font-weight: bold;
}

.swal_code_title {
  font-weight: bold;
  font-family: 'Nunito', 'Times New Roman', serif, Arial;
}

.swal-code {
  font-weight: bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#exampleModal .close {
  cursor: pointer;
  font-size: 2.5rem;
}

/*****BUTTONS*****/
.btn-blue {
  color: #5181f7;
  font-weight: bold;
  border-radius: 20px;
  padding: 7px 10px;
  transition: all 0.4s ease;
}

.btn-blue:hover {
  background-color: #5181f7;
  color: #fff;
}

.css-button-fully-rounded {
  font-size: 1rem;
  min-width: 230px;
  min-height: 40px;
  color: #fff;
  padding: 7px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border-radius: 20px;
}

.css-button-fully-rounded-quiz {
  font-size: 1rem;
  min-width: 230px;
  min-height: 40px;
  color: #fff;
  padding: 10px 90px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border-radius: 20px;
}

.primary-button-blue-bg {
  background: linear-gradient(to bottom, #5072ff, #12a7cb);
}

.primary-button-green-bg {
  background: linear-gradient(to bottom, #14d022, #0bd680);
}

.primary-button-red-bg,
.primary-button-red-bg:hover {
  border: 2px solid #ff4040;
  background: #fff;
  color: #ff4040;
  box-shadow: none;
}

.primary-buton-blue-bg,
.primary-buton-blue-bg:hover {
  border: 2px solid #0084ff;
  background: #fff;
  color: #0084ff;
  box-shadow: none;
}

.css-button-fully-rounded--green {
  min-width: 230px;
  height: 40px;
  color: #fff;
  padding: 7px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border: none !important;
  border-radius: 20px;
  background: linear-gradient(180deg, #14d022 0%, #0bd680 100%);
}

.css-button-fully-rounded--red {
  min-width: 230px;
  height: 40px;
  color: #fff;
  padding: 7px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #ff4040;
  background-color: #ff4040;
}

.css-button-fully-rounded--green:hover {
  border: 2px solid #0bd680 !important;
  padding: 5px 10px;
  background: #fff;
  color: #0bd680;
}

.css-button-fully-rounded--blue {
  min-width: 230px;
  height: 40px;
  color: #4083ff;
  padding: 7px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #4083ff;
  background: transparent;
}

.css-button-fully-rounded--blue:hover {
  border: none !important;
  padding: 5px 10px;
  background: #4083ff;
  color: #fff;
}

.btn-cards {
  background: rgba(20, 208, 34, 1);
  /* Old Browsers */
  background: -moz-linear-gradient(
    top,
    rgba(20, 208, 34, 1) 0%,
    rgba(11, 214, 128, 1) 100%
  );
  /* FF3.6+ */
  background: -webkit-linear-gradient(
    top,
    rgba(20, 208, 34, 1) 0%,
    rgba(11, 214, 128, 1) 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(20, 208, 34, 1) 0%,
    rgba(11, 214, 128, 1) 100%
  );
  /* Opera 11.10+ */
  background: linear-gradient(
    to bottom,
    rgba(20, 208, 34, 1) 0%,
    rgba(11, 214, 128, 1) 100%
  );
  /* W3C */
  color: white !important;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-cards:hover {
  background: rgba(20, 208, 34, 1);
  /* Old Browsers */
  background: -moz-linear-gradient(
    top,
    rgba(20, 208, 34, 1) 0%,
    rgba(11, 214, 128, 1) 100%
  );
  /* FF3.6+ */
  background: -webkit-linear-gradient(
    top,
    rgba(20, 208, 34, 1) 0%,
    rgba(11, 214, 128, 1) 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(20, 208, 34, 1) 0%,
    rgba(11, 214, 128, 1) 100%
  );
  /* Opera 11.10+ */
  background: linear-gradient(
    to bottom,
    rgba(20, 208, 34, 1) 0%,
    rgba(11, 214, 128, 1) 100%
  );
  /* W3C */
}

/*****BUTTONS*****/

.drop-app {
  background-color: #ffffff !important;
  border-radius: none !important;
  box-shadow: none !important;
  position: fixed;
  z-index: 9999;
  border-radius: 39px 39px 39px 39px;
  -moz-border-radius: 39px 39px 39px 39px;
  -webkit-border-radius: 39px 39px 39px 39px;
  top: 32% !important;
  transform: translateY(-32%) !important;
}

.app {
  text-align: center !important;
}

.dropdown-menu {
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
}

.field-title {
  font-family: 'Nunito Bold', 'Times New Roman', serif, Arial;
  font-size: 16px;
  color: #707070;
}

.subtitle {
  font-family: 'Nunito SemiBold', 'Times New Roman', serif, Arial;
  font-size: 20px;
  color: #4d4d4d;
}

.user-name {
  font-family: 'Nunito SemiBold', 'Times New Roman', serif, Arial;
  font-size: 14px;
  color: #4d4d4d;
}

.descent {
  font-family: 'Nunito Medium', 'Times New Roman', serif, Arial;
  font-size: 18px;
  color: #4d4d4d;
}

.paragraph {
  font-family: 'Nunito Regular', 'Times New Roman', serif, Arial;
  font-size: 18px;
  color: #4d4d4d;
}

.container-title {
  font-family: 'Nunito ExtraBold', 'Times New Roman', serif, Arial;
  font-size: 24px;
  color: #707070;
}

.item-name {
  font-family: 'Nunito Bold', 'Times New Roman', serif, Arial;
  font-size: 22px;
  color: #4d4d4d;
}

.btn-cards {
  margin-bottom: 0px;
  bottom: 0px;
}

.general-title,
.call-to-action,
.flat-button {
  font-family: 'Nunito ExtraBold', 'Times New Roman', serif, Arial;
}

.general-title {
  font-size: 32px;
  color: #4d4d4d;
}

.call-to-action {
  font-size: 18px;
  color: #ffffff;
}

.flat-button {
  font-size: 18px;
  color: #4083ff;
}

.signin-button > div > div > svg {
  height: 50px;
  width: 100%;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Nunito', 'Times New Roman', serif, Arial;
  background-color: #f5f9fc;
}

a {
  text-decoration: none !important;
}

/* accordion styles */
.accordion-component .question {
  border: 1px solid #f0f0f0;
  /* Borde gris claro */
  padding: 5px;
  border-radius: 10px;
  /* Bordes redondeados */
  background-color: #ffffff;
  /* Fondo blanco */
  margin-bottom: 10px;
  /* Espacio entre acordeones */
  overflow: hidden;
  /* Oculta el contenido que sobresale */
}

.accordion-component-question-text {
  text-align: left;
  margin-right: 5%;
}

.mat-checkbox-layout {
  width: 100% !important;
}

.mat-checkbox-label-before .mat-checkbox-inner-container {
  margin-left: auto !important;
  margin-right: 0 !important;
}

/* MATERIAL Radio button styles */
.mat-radio-button .mat-radio-label .mat-radio-container {
  margin-left: auto !important;
}

.mdc-form-field--align-end > label {
  margin-left: 0 !important;
  margin-right: auto !important;
}

.mdc-form-field {
  width: 100%;
}

/* MATERIAL select styles */
.mat-form-field-underline {
  display: none;
}

/* MATERIAL mat-option  */
.mat-select-panel mat-option.mat-option {
  height: unset;
}

.mat-option-text.mat-option-text {
  white-space: normal;
  line-height: 1;
}

/* MATERIAL ngx-charts-bar-horizontal  */

ngx-charts-bar-horizontal .x-axis-label {
  white-space: normal;
  line-height: 1;
  height: unset;
}

.ngx-charts-tooltip-content.type-tooltip {
  max-width: 550px;
}

/* MATERIAL Select */
.mat-mdc-option .mat-pseudo-checkbox-minimal {
  display: none;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
  border-radius: 17px;
}

.mdc-line-ripple::before {
  display: none;
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
}

.activity .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
  border: none !important;
  border-radius: 17px;
}

/* Evaluation quiz code section */
.evaluation-quiz-code {
  background-color: #f6fafc;
  border-radius: 29px;
  padding: 15px 20px;
}

.evaluation-quiz-code h1 {
  color: #00a0be;
  font-family: 'Nunito ExtraBold', 'Times New Roman', serif, Arial;
  font-size: 80px;
  font-weight: 900;
}

.evaluation-quiz-text-disclaimer {
  font-size: 15px;
  font-weight: bold;
  font-style: italic;
}

.delete-account-form .mdc-form-field > label {
  margin-bottom: 0 !important;
}

.custom-swal-modal {
  border-radius: 3rem !important;
  font-family: 'Nunito', 'Times New Roman', serif, Arial;
  padding: 2rem 1rem;
}

.custom-swal-modal .swal2-title {
  font-size: 1.75rem;
}

.custom-swal-modal strong {
  font-family: 'Nunito ExtraBold', 'Times New Roman', serif, Arial;
}

.custom-swal-close-button {
  color: #4d4d4d;
}

@media (max-width: 767px) {
  .btn-enter {
    margin-top: 75px !important;
  }

  .custom-swal-modal {
    width: 100% !important;
  }

  .custom-swal-modal #swal2-title {
    padding: 0.5em 0.5em 0 !important;
  }
}
